<template>
  <v-container>
    <div v-if="!cargando_pagina">
      <v-row v-if="isTransbank">
        <v-fade-transition>

          <v-col cols="12" v-if="!transbank.verificado">
            <h2 class="text-center font-weight-medium">Verificando pago</h2>
            <p class="text-center">Estamos verificando su pago, por favor espere.</p>
            <p style="font-size: 4.5rem" class="text-center">{{ tiempo }}</p>

          </v-col>

          <v-col cols="12" v-if="!transbank.pago_ok && transbank.verificado">
            <h2 class="text-center font-weight-medium">Pago no verificado</h2>
            <div class="text-center py-2">
              <v-icon color="black" style="font-size: 4.5rem;">mdi-close-thick</v-icon>
            </div>
            <p class="text-center">Se nos ha informado que su pago no pudo ser realizado</p>
          </v-col>

          <v-col cols="12" v-if="transbank.pago_ok && transbank.verificado">

            <h2 class="text-center font-weight-medium">Pago verificado</h2>
            <div class="text-center py-2">
              <v-icon color="success" style="font-size: 4.5rem;">mdi-check-bold</v-icon>
            </div>
            <p class="text-center">Su pago fue vertificado con éxito</p>

          </v-col>

        </v-fade-transition>

      </v-row>
      <v-row v-if="!isTransbank">
        <v-col cols="12" v-if="!pago_verificado && !error_verificacion">
          <h2 class="text-center font-weight-medium">Verificando pago</h2>
          <p class="text-center">Estamos verificando su pago, por favor espere.</p>
          <p style="font-size: 4.5rem" class="text-center">{{ tiempo }}</p>

        </v-col>

        <v-fade-transition>
          <v-col cols="12" v-if="error_verificacion">
            <h2 class="text-center font-weight-medium">Pago no verificado</h2>
            <div class="text-center py-2">
              <v-icon color="black" style="font-size: 4.5rem;">mdi-close-thick</v-icon>
            </div>
            <p class="text-center">Hubo un problema al verificar su pago en estos momentos</p>
            <p class="text-center">Volviendo a verificar su pago en {{ reintento }} segundos</p>

          </v-col>
        </v-fade-transition>

        <v-fade-transition>
          <v-col cols="12" v-if="pago_verificado">

            <h2 class="text-center font-weight-medium">Pago verificado</h2>
            <div class="text-center py-2">
              <v-icon color="success" style="font-size: 4.5rem;">mdi-check-bold</v-icon>
            </div>
            <p class="text-center">Su pago fue vertificado con éxito</p>

          </v-col>
        </v-fade-transition>
        <v-fade-transition>
          <v-row v-if="pago_verificado || error_verificacion">
            <v-col cols="6" class="text-right">
              <v-btn color="primary" style="width: 180px" @click="irAPortal">
                <v-icon left>mdi-home</v-icon>
                <span>ir al portal</span></v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn color="primary" style="width: 180px" @click="irAMiCuenta">
                <v-icon left>mdi-account</v-icon>
                <span>ir a mi cuenta</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-fade-transition>


      </v-row>

    </div>

    <v-fade-transition>
      <div v-if="cargando_pagina" class="text-center">

        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>

      </div>

    </v-fade-transition>


  </v-container>
</template>

<script>
import carroEntity from "@/model/carro/carro.entity";
import caratulaEntity from "@/model/caratula/caratula.entity";
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

export default {
  name: "VerificacionPagoView",
  data() {
    return {
      tiempo: 99,
      reintento: 5,
      error_verificacion: false,
      pago_verificado: false,
      cargando_pagina: true,
      isTransbank: false,
      transbank: {
        verificado: false,
        pago_ok: false
      }
    }
  },
  created() {

    if (this.$route.params.seccion != null) {

      this.getTiempo();

      switch (this.$route.params.seccion) {
        case "carro":
          switch (this.$route.params.recaudador) {
            case "khipu":
              this.cargando_pagina = false;
              this.verificarCarro(this.$route.query.id_carro);
              break;
            default:
              console.log(this.$route.params.recaudador)
              console.log("no se ha ingresado el tipo de recaudador ")
               window.location = '/cliente/compras'
          }
          break;
        case "saldo":
          switch (this.$route.params.recaudador) {

            default:
              this.verificarSaldo(this.$route.params.recaudador);

          }

          break;
        default:
          console.log("la seccion ingresada no es válida")
          window.location = '/cliente/compras';
      }

    } else {
      window.location = '/cliente/compras';
      this.error_verificacion = true;
    }


  },
  methods: {

    validate(tipo, id) {
      switch (tipo) {
        case "carro":
          this.verificarCarro(id);
          this.cargando_pagina = false;
          break;
        case "saldo":

          this.cargando_pagina = false
          break;
        default:
          location.href = "/"
      }

    },

    verificarCarro(id) {
      if (this.isTransbank) {
        console.log("solo verificar una vez")
      } else {
        carroEntity.getEstadoPagoCarro(id).then(resp => {
          if (resp.obj) {
            this.pago_verificado = true;
          } else {
            setTimeout(() => {
              this.verificarCarro(id)
            }, 5000)
          }
        });
      }


    },
    verificarSaldo(id) {
      caratulaEntity.getEstadoPagoSaldoCaratula(id).then(resp => {
        console.log("resp:", resp)
        if (resp.obj) {
          this.pago_verificado = true;
        } else {
          setTimeout(() => {
            this.verificarSaldo(id)
          }, 5000)
        }
      })
    },
    getTiempo() {
      let intervalId = setInterval(() => {
        console.log(intervalId)
        if (!this.pago_verificado) {
          if (this.tiempo > 0) {
            this.tiempo--
          } else {
            this.error_verificacion = true;
            clearInterval(intervalId);
            this.getRestart();
          }
        } else {
          clearInterval(intervalId);
        }
      }, 1000)
    },
    getRestart() {
      setInterval(() => {
        if (this.reintento > 0) {
          this.reintento--
        } else {
          location.reload();
        }
      }, 1000)
    },
    irAMiCuenta() {

      usuarioEntity.isSessionActive().then(res => {
        if (res) {
          this.$router.push("/cliente")
        } else {
          EventBus.$emit("modal-login", true);
        }
      })

    },
    irAPortal() {
      this.$router.push("/")
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
